<template>
  <div v-if="eventData">
    <v-card>
      <v-card-title>
        <div v-if="isAdmin && eventData.outcome && eventData.outcome.isNeedManualUpdate" class="w-full">
          <v-alert color="warning" text>
            <div class="font-weight-semibold mb-1">
              Need manual update status
              <v-btn class="float-right delete-event-button" depressed color="warning" @click="isManualUpdated = true">
                Updated
              </v-btn>
            </div>
          </v-alert>
        </div>
        <div class="mb-2 mb-sm-0 w-full">
          <v-icon @click="$router.back()">{{ icons.mdiArrowLeftBoldCircleOutline }}</v-icon>
          Event Details
          <v-chip
            normal
            :color="resolveEventStatusVariant(eventData.status)"
            :class="`${resolveEventStatusVariant(eventData.status)}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ eventData.status }}
          </v-chip>
          <v-btn v-if="isAdmin" class="float-right delete-event-button" depressed color="error" @click="isShowDeleteEventDialog = true">
            Delete
          </v-btn>
          <v-dialog v-model="isShowDeleteEventDialog" persistent max-width="600px">
            <v-card>
              <v-form class="delete-event-dialog" ref="form" v-model="valid">
                <v-card-title>
                  <span>Are you sure you want to proceed?</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-select
                          v-model="reason"
                          placeholder="Reason"
                          :items="reasonOptions"
                          :rules="[validators.required]"
                          item-text="title"
                          item-value="value"
                          outlined
                          dense
                          clearable
                          hide-details
                        ></v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-textarea
                          id="comment-field"
                          v-model="comment"
                          label="Comment"
                          :rules="[validators.required, validators.maxLengthValidator(comment, 255)]"
                          required
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="isShowDeleteEventDialog = false"> No </v-btn>
                  <v-btn color="blue darken-1" text @click="onSubmit()"> Yes </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
          <v-dialog v-model="isManualUpdated" persistent max-width="600px">
            <v-card>
              <v-form class="delete-event-dialog" ref="form" v-model="valid">
                <v-card-title>
                  <span>Are you sure you updated the status manually?</span>
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="isManualUpdated = false"> No </v-btn>
                  <v-btn color="blue darken-1" text @click="manualUpdated()"> Yes </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
        </div>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" lg="12">
            <table class="event-table">
              <tr>
                <td class="event-title">
                  <p class="text-no-wrap text--primary font-weight-medium">Event ID:</p>
                </td>
                <td>
                  <p class="event-id">{{ eventData.eventId }}</p>
                </td>
              </tr>
              <tr>
                <td class="event-title">
                  <p class="text-no-wrap text--primary font-weight-medium">Merchant Name:</p>
                </td>
                <td>
                  <p class="merchant-name">{{ eventData.merchantName }}</p>
                </td>
              </tr>
              <tr>
                <td class="event-title">
                  <p class="text-no-wrap text--primary font-weight-medium">Website Name:</p>
                </td>
                <td>
                  <p class="website-name">{{ eventData.websiteName }}</p>
                </td>
              </tr>
              <tr>
                <td class="event-title">
                  <p class="text-no-wrap text--primary font-weight-medium">Card Type:</p>
                </td>
                <td>
                  <p class="card-type">
                    <img
                      :src="require(`@/assets/images/svg/${eventData.cardType}-${$vuetify.theme.dark ? 'dark' : 'light'}.svg`)"
                      height="30px"
                      width="40px"
                      class="me-2 v-image__image--contain"
                      alt="Card type logo">
                  </p>
                </td>
              </tr>
              <tr>
                <td class="event-title">
                  <p class="text-no-wrap text--primary font-weight-medium">
                    {{ definedEventIdName(eventData.service) }}:
                  </p>
                </td>
                <td>
                  <p class="alert-id">{{ eventData.alertId }}</p>
                </td>
              </tr>
              <tr v-if="eventData.service !== eventServiceEnum.VISA_RDR">
                <td class="event-title">
                  <p class="text-no-wrap text--primary font-weight-medium">Event Type:</p>
                </td>
                <td>
                  <p class="event-type">{{ eventData.alertType }}</p>
                </td>
              </tr>
              <tr>
                <td class="event-title">
                  <p class="text-no-wrap text--primary font-weight-medium">Service:</p>
                </td>
                <td>
                  <p class="service">{{ eventData.service }}</p>
                </td>
              </tr>
              <tr>
                <td class="event-title">
                  <p class="text-no-wrap text--primary font-weight-medium">Event Time:</p>
                </td>
                <td>
                  <p class="alert-date">
                    {{ eventData.alertTimestamp | moment(`${getDateFormat(eventData.alertTimestamp)}`) }}
                  </p>
                </td>
              </tr>
              <tr>
                <td class="event-title">
                  <p class="text-no-wrap text--primary font-weight-medium">Created At:</p>
                </td>
                <td>
                  <p class="created-at">
                    {{ eventData.createdAt | moment('YYYY-MM-DD HH:mm:ss') }}
                  </p>
                </td>
              </tr>
              <tr v-if="eventData.service === eventServiceEnum.MASTERCARD_ALERT">
                <td class="event-title">
                  <p class="text-no-wrap text--primary font-weight-medium">Age:</p>
                </td>
                <td>
                  <p class="age">
                    {{ eventData.age }}
                  </p>
                </td>
              </tr>
              <tr>
                <td class="event-title">
                  <p class="text-no-wrap text--primary font-weight-medium">Issuer:</p>
                </td>
                <td>
                  <p class="issuer">
                    {{ eventData.issuer }}
                  </p>
                </td>
              </tr>
              <tr v-if="eventData.authCode">
                <td class="event-title">
                  <p class="text-no-wrap text--primary font-weight-medium">AUTH Code:</p>
                </td>
                <td>
                  <p class="authCode">
                    {{ eventData.authCode }}
                  </p>
                </td>
              </tr>
              <tr>
                <td class="event-title">
                  <p class="text-no-wrap text--primary font-weight-medium">Card Number:</p>
                </td>
                <td>
                  <p class="card-number">
                    {{ eventData.cardNumber }}
                  </p>
                </td>
              </tr>
              <tr>
                <td class="event-title">
                  <p class="text-no-wrap text--primary font-weight-medium">Card BIN:</p>
                </td>
                <td>
                  <p class="card-bin">
                    {{ eventData.cardBin }}
                  </p>
                </td>
              </tr>
              <tr>
                <td class="event-title">
                  <p class="text-no-wrap text--primary font-weight-medium">CAID:</p>
                </td>
                <td>
                  <p class="caid">
                    {{ eventData.caid }}
                  </p>
                </td>
              </tr>
              <tr>
                <td class="event-title">
                  <p class="text-no-wrap text--primary font-weight-medium">Card Last 4:</p>
                </td>
                <td>
                  <p class="card-last-four-digits">
                    {{ eventData.cardLast }}
                  </p>
                </td>
              </tr>
              <tr>
                <td class="event-title">
                  <p class="text-no-wrap text--primary font-weight-medium">Transaction Date:</p>
                </td>
                <td>
                  <p class="transaction-date">
                    {{ eventData.transactionDate | moment(`${getDateFormat(eventData.transactionDate)}`) }}
                  </p>
                </td>
              </tr>
              <tr>
                <td class="event-title">
                  <p class="text-no-wrap text--primary font-weight-medium">Merchant Descriptor:</p>
                </td>
                <td>
                  <p class="merchant-descriptor">
                    {{ eventData.merchantDescriptor }}
                  </p>
                </td>
              </tr>
              <tr>
                <td class="event-title">
                  <p class="text-no-wrap text--primary font-weight-medium">Member ID:</p>
                </td>
                <td>
                  <p class="member-id">
                    {{ eventData.memberId }}
                  </p>
                </td>
              </tr>
              <tr>
                <td class="event-title">
                  <p class="text-no-wrap text--primary font-weight-medium">MCC:</p>
                </td>
                <td>
                  <p class="mcc">
                    {{ eventData.merchantCategoryCode }}
                  </p>
                </td>
              </tr>
              <tr>
                <td class="event-title">
                  <p class="text-no-wrap text--primary font-weight-medium">Merchant Member Name:</p>
                </td>
                <td>
                  <p class="merchant-member-name">
                    {{ eventData.merchantMemberName }}
                  </p>
                </td>
              </tr>
              <tr>
                <td class="event-title">
                  <p class="text-no-wrap text--primary font-weight-medium">Amount:</p>
                </td>
                <td>
                  <p class="amount">
                    {{ eventData.amount }}
                  </p>
                </td>
              </tr>
              <tr>
                <td class="event-title">
                  <p class="text-no-wrap text--primary font-weight-medium">Currency:</p>
                </td>
                <td>
                  <p class="currency">
                    {{ eventData.currency }}
                  </p>
                </td>
              </tr>
              <tr v-if="eventData.additionalInfo && eventData.additionalInfo.transactionType">
                <td class="event-title">
                  <p class="text-no-wrap text--primary font-weight-medium">Transaction Type:</p>
                </td>
                <td>
                  <p class="transaction-type">
                    {{ eventData.additionalInfo.transactionType }}
                  </p>
                </td>
              </tr>
              <tr v-if="eventData.additionalInfo && eventData.additionalInfo.alertInitiator">
                <td class="event-title">
                  <p class="text-no-wrap text--primary font-weight-medium">Event Source:</p>
                </td>
                <td>
                  <p class="alert-initiator">
                    {{ eventData.additionalInfo.alertInitiator }}
                  </p>
                </td>
              </tr>
              <tr v-if="eventData.additionalInfo && eventData.additionalInfo.is3dSecure">
                <td class="event-title">
                  <p class="text-no-wrap text--primary font-weight-medium">Is 3D Secure:</p>
                </td>
                <td>
                  <p class="is-3d-secure">
                    {{ eventData.additionalInfo.is3dSecure }}
                  </p>
                </td>
              </tr>
              <tr>
                <td class="event-title">
                  <p class="text-no-wrap text--primary font-weight-medium">Source:</p>
                </td>
                <td>
                  <p class="source">
                    {{ eventData.source }}
                  </p>
                </td>
              </tr>
              <tr>
                <td class="event-title">
                  <p class="text-no-wrap text--primary font-weight-medium">ARN:</p>
                </td>
                <td>
                  <p class="arn">
                    {{ eventData.arn }}
                  </p>
                </td>
              </tr>
              <tr>
                <td class="event-title">
                  <p class="text-no-wrap text--primary font-weight-medium">Transaction ID:</p>
                </td>
                <td>
                  <p class="transaction-id">
                    {{ eventData.transactionId }}
                  </p>
                </td>
              </tr>
              <tr v-if="eventData.chargebackAmount">
                <td class="event-title">
                  <p class="text-no-wrap text--primary font-weight-medium">Chargeback Amount:</p>
                </td>
                <td>
                  <p class="chargeback-amount">
                    {{ eventData.chargebackAmount }}
                  </p>
                </td>
              </tr>
              <tr v-if="eventData.chargebackCurrency">
                <td class="event-title">
                  <p class="text-no-wrap text--primary font-weight-medium">Chargeback Currency:</p>
                </td>
                <td>
                  <p class="chargeback-currency">
                    {{ eventData.chargebackCurrency }}
                  </p>
                </td>
              </tr>
              <tr v-if="(eventData.comment && eventData.status !== chargebackStatusEnum.DELETED) || (eventData.comment && isAdmin)">
                <td class="alert-title">
                  <p class="text-no-wrap text--primary font-weight-medium">Comment:</p>
                </td>
                <td>
                  <p class="comment">
                    {{ eventData.comment }}
                  </p>
                </td>
              </tr>
              <tr v-if="eventData.reason">
                <td class="alert-title">
                  <p class="text-no-wrap text--primary font-weight-medium">Reason:</p>
                </td>
                <td>
                  <p class='reason'>
                    {{ eventData.reason }}
                  </p>
                </td>
              </tr>
            </table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card v-if="eventData.outcome" class="mt-5" id="outcome-logs">
      <v-card-title>
        <p class="mb-2 mb-sm-0">Outcome logs</p>
        <v-spacer></v-spacer>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" lg="12">
            <table class="event-table">
              <tr v-if="eventData.outcome.initiator">
                <td class="event-title">
                  <p class="text-no-wrap text--primary font-weight-medium">Outcome Initiator:</p>
                </td>
                <td>
                  <p class="outcome-initiator">{{ eventData.outcome.initiator.fullName }}</p>
                </td>
              </tr>
              <tr>
                <td class="event-title">
                  <p class="text-no-wrap text--primary font-weight-medium">Refunded:</p>
                </td>
                <td>
                  <p class="outcome-refunded-status">{{ capitalizeWords(eventData.outcome.refunded) }}</p>
                </td>
              </tr>
              <tr>
                <td class="event-title">
                  <p class="text-no-wrap text--primary font-weight-medium">Changed status to:</p>
                </td>
                <td>
                  <p class="outcome-changed-status-to">{{ capitalizeWords(eventData.outcome.status) }}</p>
                </td>
              </tr>
              <tr>
                <td class="event-title">
                  <p class="text-no-wrap text--primary font-weight-medium">Outcome provided:</p>
                </td>
                <td>
                  <p class="outcome-provided-date">{{ eventData.outcome.createdAt | moment('YYYY-MM-DD HH:mm:ss') }}</p>
                </td>
              </tr>
            </table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card v-if="eventLogs && eventLogs.length > 0" class="mt-5" id="event-history">
      <v-card-title> Event History </v-card-title>

      <v-card-text>
        <v-timeline dense class="timeline-custom-dense timeline-custom-dots">
          <v-timeline-item v-for="(log, key) in eventLogs" :key="key" color="error" small>
            <div class="d-flex justify-space-between align-center flex-wrap">
              <h4 class="font-weight-medium me-1">
                {{ log.eventName }}
              </h4>
            </div>
            <p class="mb-0 outcome-overview">{{ log.createdAt | moment('YYYY-MM-DD HH:mm:ss') }} {{ log.eventName !== chargebackEventEventsEnum.EVENT_DELETED || isAdmin ?  log.message : log.message.replace(` with note "${eventData.comment} Reason: ${eventData.reason}"`, '') }}</p>
          </v-timeline-item>
        </v-timeline>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mdiArrowLeftBoldCircleOutline } from '@mdi/js';
import store from '@/store';
import router from '@/router';
import { ref } from '@vue/composition-api';
import { EventServiceEnum, definedEventId } from '@/enums/event-service.enum';
import { EventTypeClearName } from '@/enums/event-type.enum';
import ChargebackEventDeletedReason from '@/enums/chargeback-event-deleted-reason.enum';
import Vue from 'vue';
import { maxLengthValidator, required } from '@core/utils/validation';
import { getDateFormat } from '@core/utils/filter';
import useChargebackEventsList from '@/views/chargebacks/chargeback-events/useChargebackEventsList';
import { ChargebackStatusEnum } from '@/enums/chargeback-status.enum';
import { ChargebackEventEventsEnum } from '@/enums/chargeback-event-events.enum';
import { capitalizeWords } from '@core/utils';

export default {
  setup() {
    const { resolveEventStatusVariant, isAdmin } = useChargebackEventsList();
    const eventData = ref(null);
    const eventLogs = ref(null);
    const comment = ref('');
    const reason = ref(null);
    const reasonOptions = ChargebackEventDeletedReason;
    const chargebackStatusEnum = ChargebackStatusEnum;
    const chargebackEventEventsEnum = ChargebackEventEventsEnum;
    const isShowDeleteEventDialog = ref(false);
    const isManualUpdated = ref(false);

    const valid = ref(false);
    const form = ref(null);

    const validate = () => {
      form.value.validate();
    };

    const onSubmit = () => {
      const isFormValid = form.value.validate();

      if (!isFormValid) return;
      store
        .dispatch('chargebackEvent/patchStatus', {
          id: router.currentRoute.params.id,
          status: 'deleted',
          comment: comment.value,
          reason: reason.value,
        })
        .then(() => {
          eventData.value.status = 'deleted';
          isShowDeleteEventDialog.value = false;
        })
        .catch(() => {
          Vue.notify({
            type: 'error',
            title: 'Event',
            text: 'Cannot mark alert as deleted',
          });
        });
    };

    const manualUpdated = () => {
      store
        .dispatch('chargebackEvent/manualUpdated', {
          id: router.currentRoute.params.id,
          isManualUpdated: true,
        })
        .then(() => {
          eventData.outcome.isNeedManualUpdate = false;
          isManualUpdated.value = false;
        })
        .catch(() => {
          Vue.notify({
            type: 'error',
            title: 'Event',
            text: 'Cannot delete warning',
          });
        });
    };

    store
      .dispatch('chargebackEvent/fetchEventById', { id: router.currentRoute.params.id })
      .then(response => {
        eventData.value = response.data;
      })
      .catch(error => {
        if (error.response.status === 404) {
          eventData.value = undefined;
        }
      });

    store
      .dispatch('chargebackEvent/fetchEventLogsById', { id: router.currentRoute.params.id })
      .then(response => {
        eventLogs.value = response.data;
      })
      .catch(error => {
        if (error.response.status === 404) {
          eventLogs.value = undefined;
        }
      });

    const definedEventIdName = definedEventId;
    const eventTypeClearName = EventTypeClearName;
    const eventServiceEnum = EventServiceEnum;

    return {
      isAdmin,
      eventServiceEnum,
      isShowDeleteEventDialog,
      isManualUpdated,
      reason,
      reasonOptions,
      onSubmit,
      manualUpdated,
      valid,
      form,
      comment,
      eventData,
      eventLogs,
      definedEventIdName,
      resolveEventStatusVariant,
      getDateFormat,
      validators: { required, maxLengthValidator },
      validate,
      eventTypeClearName,
      chargebackStatusEnum,
      chargebackEventEventsEnum,
      icons: {
        mdiArrowLeftBoldCircleOutline,
      },
      capitalizeWords,
    };
  },
};
</script>

<style lang="scss" scoped>
.v-timeline-item {
  ::v-deep > .v-timeline-item__body {
    max-width: calc(100% - 45px) !important;
  }
}
</style>
